const dateAgeFormat = {}

function calculateAge(date) {
  const dob = new Date(date)
  const diffMs = Date.now() - dob.getTime()
  const ageDt = new Date(diffMs)
  const age = Math.abs(ageDt.getUTCFullYear() - 1970)
  return `${age}`
}
/* eslint-disable func-names */
dateAgeFormat.install = function (Vue) {
  Vue.filter('age-format', val => calculateAge(val))
}
/* eslint-enable func-names */
export default dateAgeFormat
