export default {
  namespaced: true,
  state: {
    user: {},
    token: '',
  },
  getters: {
    getUser: state => state.user,
    isLoggedIn: state => !!state.token,
    requireResetPassword: state => !!state.user.ChangedPassword,
    getStaffProfile: state => (state.user ? state.user.idProfile : 0),
    getToken: state => state.token,
  },
  mutations: {
    auth_success(state, { token, user }) {
      state.token = token
      state.user = user
    },
    auth_user(state, user) {
      state.user = user
    },
    auth_password_reseted(state) {
      state.user.ChangedPassword = false
    },
    auth_denied(state) {
      state.user = {}
      state.token = ''
    },
  },
}
