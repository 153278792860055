export default {
  namespaced: true,
  state: {
    idProfile: null,
    idCandidate: null,
  },
  getters: {
    getIdProfile: state => state.idProfile,
    getIdCandidate: state => state.idCandidate,
  },
  mutations: {
    setIdProfile(state, idProfile) {
      state.idProfile = idProfile
    },
    setIdCandidate(state, idCandidate) {
      state.idCandidate = idCandidate
    },
  },
}
