/* eslint-disable no-param-reassign */
const formElement = {

  install(Vue) {
    /*
        @params
        input
            dataList: Array Of { Id: Number, Name: String }
        output
        dataList: Array Of { value: Number, text: String }
    */
    Vue.prototype.convertCatalogListToSelectOptionList = dataList => {
      dataList.forEach(data => {
        delete Object.assign(data, { value: data.Id }).Id
        delete Object.assign(data, { text: data.Name }).Name
      })
      return dataList
    }
  },
}
/* eslint-enable no-param-reassign */
export default formElement
