import moment from 'moment'

const timeFormat = {}

function convertTimeFormat(time) {
  if (time === '0') {
    return '00:00:00'
  }
  const timecocat = `01-01-2020 ${time}`
  const timeformated = moment(timecocat).format('HH:mm:ss')
  return timeformated
}
/* eslint-disable func-names */
timeFormat.install = function (Vue) {
  Vue.filter('time-format', val => convertTimeFormat(val))
}
/* eslint-enable func-names */
export default timeFormat
