import moment from 'moment'

const dateTimeFormat = {}

function convertDateFormat(date) {
  const datetimeformated = moment(date).format('MM-DD-YYYY HH:mm:ss')
  return datetimeformated
}
/* eslint-disable func-names */
dateTimeFormat.install = function (Vue) {
  Vue.filter('datetime-format', val => convertDateFormat(val))
}
/* eslint-enable func-names */
export default dateTimeFormat
