import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/changeMyPassword',
      name: 'changeMyPassword',
      component: () => import('@/views/Auth/RecoverPassword.vue'),
      meta: {
        requiresAuth: true,
        layout: 'full',
      },
    },
    {
      path: '/tickets',
      name: 'tickets',
      component: () => import('@/views/Tickets/Tickets.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/catalogs',
      name: 'catalogs',
      component: () => import('@/views/Settings/Catalogs/Catalogs.vue'),
      meta: {
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/Profiles/Profiles.vue'),
      meta: {
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/system/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/',
      redirect: 'login',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  const { requiresAuth, adminAuth } = to.meta
  const isLoggedIn = store.getters['user-auth/isLoggedIn']
  const requireResetPassword = store.getters['user-auth/requireResetPassword']
  const profile = store.getters['user-auth/getStaffProfile']
  if (requiresAuth) { // if requiered auth
    if (!isLoggedIn) next('/login') // if is not logged redirect to login
    else if (requireResetPassword && to.name !== 'changeMyPassword') next('/changeMyPassword')
    else if (adminAuth && profile === 1) next() // is admin and require admin access
    else next()
  }
  next()
})

export default router
